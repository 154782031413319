<template>
  <main
    v-if="survey"
    class="survey"
  >
    <div
      v-if="isShowPreview"
      class="survey__preview"
    >
      <div class="survey__banner">
        <img
          v-if="survey.start_banner"
          :src="getImage(survey.start_banner)"
          alt=""
        />
      </div>
      <div class="survey__title">{{ survey.start_title }}</div>
      <div class="survey__preview-text">
        {{ survey.start_description }}
      </div>
      <div
        class="survey__preview-btn survey__btn"
        @click="showQuestion"
      >
        Вперёд
      </div>
    </div>
    <div
      v-if="isShowQuestion"
      class="survey__main"
    >
      <div class="survey__progress">
        <div class="survey__progress-top">
          <p class="survey__progress-pagination">{{ progress }}/{{ survey.question.length }}</p>
          <p
            v-if="error"
            class="survey__error"
          >
            ! Вы не ответили на данный вопрос
          </p>
        </div>
        <progress
          id="progress-bar"
          :value="progress"
          :max="survey.question.length"
        ></progress>
      </div>
      <div
        v-if="survey.question[progress - 1]"
        class="survey__content"
      >
        <div class="survey__banner">
          <img
            :src="getImage(survey.question[progress - 1].banner)"
            alt=""
          />
        </div>
        <div class="survey__title">
          {{ survey.question[progress - 1].label }}
        </div>
        <div class="survey__text">
          {{ survey.question[progress - 1].description }}
        </div>
        <form class="survey__form">
          <div
            v-for="(item, index) in survey.question[progress - 1].answers"
            :key="index"
            class="survey__select"
            :class="{ active: getActive(item) }"
            @click="selectedItem(survey.question[progress - 1], item)"
          >
            <div class="survey__select-text">{{ item.label }}</div>
            <div class="survey__select-checkbox"></div>
          </div>
        </form>
      </div>
      <div
        class="survey__next"
        :class="{ active: selected }"
        @click="nextPage"
      >
        Далее
      </div>
    </div>
    <div
      v-if="isShowEnd"
      class="survey__preview"
    >
      <div class="survey__banner">
        <img
          v-if="survey.start_banner"
          :src="getImage(survey.end_banner)"
          alt=""
        />
      </div>
      <div class="survey__title">{{ survey.end_title }}</div>
      <div class="survey__preview-text">
        {{ survey.end_description }}
      </div>
      <div
        class="survey__preview-btn survey__btn"
        @click="finishedSurvey"
      >
        Вперёд
      </div>
    </div>
    <div
      v-if="isShowFalse"
      class="survey__preview"
    >
      <div class="survey__banner">
        <img
          v-if="survey.false_banner_"
          :src="getImage(survey.false_banner_)"
          alt=""
        />
      </div>
      <div class="survey__title">{{ survey.false_title }}</div>
      <div class="survey__preview-text">
        {{ survey.false_description }}
      </div>
    </div>
    <div
      v-if="isShowSucces"
      class="survey__preview"
    >
      <div class="survey__banner">
        <img
          v-if="survey.false_banner_"
          :src="getImage(survey.false_banner_)"
          alt=""
        />
      </div>
      <div class="survey__title">{{ $t("survey.title") }}</div>
      <div class="survey__preview-text">
        {{ $t("survey.text") }}
      </div>
      <locale-router-link
        to="/"
        tag="div"
        class="survey__preview-btn survey__btn"
      >
        {{ $t("survey.button") }}
      </locale-router-link>
    </div>
  </main>
</template>

<script>
import {
  getQuestionnaire,
  questionnairesCheckUser,
  questionnairesEndTest,
  questionnaireResponse,
} from "@/api/questionnaire"
export default {
  name: "surveyPage",
  data() {
    return {
      slugs: [
        {
          slug: "pmk",
          id: 1,
        },
      ],
      progress: 1,
      selected: null,
      selectedList: [],
      isShowPreview: true,
      isShowQuestion: false,
      isShowEnd: false,
      isShowFalse: false,
      isShowSucces: false,
      survey: null,
      error: false,
      isManyAnswers: false,
      user: null,
    }
  },
  mounted() {
    let routePath = this.$route.path.split("-")
    if (routePath.length >= 2) {
      let id = this.slugs.find((e) => e.slug == routePath[1]).id
      getQuestionnaire(id).then((res) => {
        this.survey = res
      })
    } else {
      getQuestionnaire(1).then((res) => {
        this.survey = res
      })
    }

    if (this.$route.query.q) {
      let q = this.$route.query.q.split(" ")
      this.user = {
        card: q[0],
        phone: q[1],
        test: 1,
      }
      questionnairesCheckUser(this.user).then((res) => {
        if (!res.isTest) {
          this.isShowPreview = false
          this.isShowSucces = true
        }
      })
    } else {
      this.$router.push("/")
    }
  },
  watch: {
    progress: {
      handler() {
        this.error = false
        window.scrollTo(0, 0, "smooth")
      },
    },
    selected: {
      deep: true,
      handler() {
        this.error = false
      },
    },
    "selectedList.length": {
      handler() {
        this.error = false
      },
    },
  },
  methods: {
    showQuestion() {
      this.isShowPreview = false
      this.isShowQuestion = true
    },
    showEnd() {
      this.isShowQuestion = false
      this.isShowEnd = true
    },
    showFalse() {
      this.isShowQuestion = false
      this.isShowFalse = true
    },
    nextPage() {
      if (this.isManyAnswers ? !this.selectedList.length : !this.selected) {
        this.error = true
        window.scrollTo(0, 0, "smooth")
        return
      }
      let questionnaire = {
        phone: this.user?.phone,
        card: this.user?.card,
        test_id: 1,
        question_id: this.survey.question[this.progress - 1].id,
      }

      if (this.isManyAnswers) {
        questionnaire.answers = this.selectedList.map((e) => e.label)
        questionnaireResponse(questionnaire)
        if (this.selectedList.length === 1 && this.selectedList.find((e) => e.label === "Сигареты")) {
          this.progress = this.selectedList.find((e) => e.label === "Сигареты")?.next_order
        } else {
          this.progress = this.selectedList.filter((e) => e.label !== "Сигареты")[0]?.next_order
        }
        this.selectedList = []
        this.isManyAnswers = false
      } else {
        questionnaire.answers = this.selected.label
        questionnaireResponse(questionnaire)
        if (this.selected?.end) {
          this.selected = null
          if (this.progress == 1 || this.progress == 2) {
            this.showFalse()
            return
          }
          this.showEnd()
          return
        }

        if (this.selected?.next_order) {
          if (this.survey.question.findIndex((e) => e.order_id === this.selected.next_order)) {
            this.progress =
              this.survey.question.findIndex((e) => e.order_id === this.selected.next_order) + 1
          }
          this.selected = null
          return
        }
        if (this.progress != 7) {
          this.progress++
        } else {
          this.showEnd()
        }
      }

      this.selected = null
      this.selectedList = []
      this.isManyAnswers = false
    },
    getImage(image) {
      const urlImg = image
      if (urlImg) {
        return this.$helpers.getAbsolutePath(urlImg)
      }
    },
    getActive(item) {
      if (this.isManyAnswers) {
        return this.selectedList.findIndex((e) => e.id === item.id) != -1 ? true : false
      } else {
        return this.selected ? item.id == this.selected.id : false
      }
    },
    selectedItem(survey, item) {
      if (survey.mani_answers) {
        this.isManyAnswers = true
        // if (
        //   this.selectedList.length &&
        //   this.selectedList.find((e) => e.next_order !== item.next_order)
        // ) {
        //   this.selectedList = []
        // }
        if (this.selectedList.findIndex((e) => e.id === item.id) == -1) {
          this.selectedList.push(item)
        } else {
          this.selectedList.splice(
            this.selectedList.findIndex((e) => e.id === item.id),
            1,
          )
        }
        return
      }
      if (this.selected?.id === item.id) {
        this.selected = null
      } else {
        this.selected = item
      }
    },

    finishedSurvey() {
      questionnairesEndTest(this.user).then((res) => {
        if (res.end) {
          window.location.href = this.survey.link
          return
        }
        if (res.error) {
          alert(res.error)
        }
      })
    },
  },
}
</script>

<style
  lang="scss"
  scoped
></style>
