import axiosOriginal from "@/plugins/axiosUninterseptor"

export const getQuestionnaire = async (id) => {
    const response = await axiosOriginal.get(`questionnaires-new/${id}`)
    return response?.data
}

export const questionnairesCheckUser = async (body) => {
    const response = await axiosOriginal.post(`questionnaires_check_user`, body)
    return response?.data
}

export const questionnairesEndTest = async (body) => {
    const response = await axiosOriginal.post(`questionnaires_end_test`, body)
    return response?.data
}



export const questionnaireResponse = async (body) => {
    const response = await axiosOriginal.post(`questionnaire-response`, body)
    return response?.data
}
  